<!--活动预约-->
<template>
<div></div>
</template>

<script>
export default {
  name: "activity"
}
</script>

<style scoped>

</style>
